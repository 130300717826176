import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { get } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, take, tap } from 'rxjs/operators';

interface Cache {
  [key: string]: {
    timestamp: number;
    cacheData?: any;
  };
}

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private cache: Cache = {};
  private cacheMap$: { [key: string]: BehaviorSubject<any> } = {};
  private invalidateInNextRun: boolean;

  constructor(
    private httpClient: HttpClient,
    private logger: NGXLogger,
  ) {}

  invalidate() {
    this.invalidateInNextRun = true;
  }

  getCachedData<T>(key: string, url: string, params?: HttpParams, invalidateCache = false): Observable<T> {
    if (this.invalidateInNextRun) {
      this.invalidateInNextRun = false;
      this.logger.debug(`Invalidate cache for [${key}]`);
      this.cache[key] = {
        timestamp: Date.now(),
      };
    }
    const { cacheData, timestamp } = get(this.cache, key, {});
    if (!this.cacheMap$[key]) {
      this.cacheMap$[key] = new BehaviorSubject(cacheData);
    }
    const subject$ = this.cacheMap$[key];

    if (invalidateCache || !cacheData || !timestamp) {
      // Must make a request
      this.cache[key] = {
        timestamp: Date.now(),
      };
      return this.httpClient.get<T>(url, { params }).pipe(
        tap((result) => {
          this.cache[key] = {
            timestamp: Date.now(),
            cacheData: result,
          };
          subject$.next(result);
        }),
        take(1),
      );
    } else if (cacheData) {
      // data already in the cache
      return subject$ as any;
    } else if (timestamp) {
      // request in progress
      return subject$.pipe(filter((data) => !!data));
    }
  }
}
